import React from 'react'
import { useDispatch } from 'react-redux'
import giftImg from '../../assets/social/gift.svg'
import { openModals } from '../../redux/communityReducers/GiftReducer'
import { ActionButton, ActionButtonIcon } from './SocialButtons.styles'

const GiftBtn = ({ noButton, numGifts, postId, ...props }) => {
  const dispatch = useDispatch()

  return (
    <ActionButton
      as={noButton ? 'div' : 'button'}
      onClick={() => dispatch(openModals(postId))}
      {...props}
    >
      <ActionButtonIcon src={giftImg} alt="" />
      <span>{numGifts || 'Gift'}</span>
    </ActionButton>
  )
}

export default GiftBtn
