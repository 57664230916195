import React from 'react'
import { getImageSource } from '../../utils/getImageSource'

function extractVideoID(url) {
  if (!url) return null

  const youtubeRegExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const instagramRegExp = /(https?:\/\/(www\.)?)?instagram\.com\/p\/(\w+)/
  const youtubeMatch = url.match(youtubeRegExp)
  const instagramMatch = url.match(instagramRegExp)

  if (youtubeMatch && youtubeMatch[7].length === 11) {
    return 'https://www.youtube.com/embed/' + youtubeMatch[7] + '?playsinline=1'
  } else if (instagramMatch && instagramMatch[3].length === 11) {
    return instagramMatch[0] + '/embed/'
  } else {
    return url
  }
}

export const DesignSource = ({ data = {}, displayRichContent }) => {
  const title = data.title ? data.title : 'Decormatters'
  const source = getImageSource(data)

  switch (data.type) {
    case 'vlog':
      //invalid url check
      if (data?.blogUrl?.indexOf('http') === -1 || !displayRichContent)
        return (
          <img
            loading="lazy"
            decoding="async"
            srcSet={source.srcSet}
            sizes={source.sizes}
            src={source.src}
            alt={title}
          />
        )

      if (data?.blogUrl?.indexOf('facebook.com') >= 0)
        return (
          <iframe
            title="vlog"
            src={
              'https://www.facebook.com/plugins/video.php?href=' +
              data.blogUrl +
              '&show_text=0&width=476'
            }
            width="100%"
            height="100%"
            frameBorder="0"
            loading="lazy"
          >
            Your browser does not support the video tag.
          </iframe>
        )

      return (
        <iframe title="vlog" src={extractVideoID(data.blogUrl)} frameBorder="0">
          Your browser does not support the video tag.
        </iframe>
      )
    case 'blog':
      //invalid url check
      if (data?.blogUrl?.indexOf('http') === -1)
        return (
          <img
            loading="lazy"
            decoding="async"
            srcSet={source.srcSet}
            sizes={source.sizes}
            src={source.src}
            alt={title}
          />
        )

      return (
        <a href={data.blogUrl} target="_blank" rel="noopener noreferrer">
          <img
            loading="lazy"
            decoding="async"
            srcSet={source.srcSet}
            sizes={source.sizes}
            src={source.src}
            alt={title}
          />
        </a>
      )
    default:
      return (
        <img
          loading="lazy"
          decoding="async"
          srcSet={source.srcSet}
          sizes={source.sizes}
          src={source.src}
          alt={title}
        />
      )
  }
}
