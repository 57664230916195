import {
  ARCHIVE_USER_INSPIRATION,
  DELETE_DRAFT,
  GET_ALL_SAVED_IDS,
  SAVE_USER_INSPIRATION
} from '../../constants/ApiEndpoints'
import {
  INSPIRATION_GET,
  INSPIRATION_GET_IDS,
  INSPIRATION_REMOVED,
  INSPIRATION_SAVED
} from '../../constants/SaveInspirationConstants'
import { logEvent } from '../../utils/firebase'
import { requestHelper } from '../../utils/reduxActionUtils'
import { FEED_HARD_RESET } from '../constants'
import { fetchFeed } from '../feedInfiniteSlice'
import { gotoLogIn } from './AuthActions'
import { updateQuest } from './QuestActions'

export function loadSavedUserInspirationIds() {
  const appendant = requestHelper({})
  return async (dispatch, state) => {
    if (state().auth.user && state().auth.user.puser) {
      return false
    }
    if (state().auth.isAuthenticated === false) {
      return false
    }
    try {
      const response = await fetch(GET_ALL_SAVED_IDS, appendant)
      const responseJson = await response.json()

      if (response.status === 200)
        dispatch(getSavedInspirationIds(responseJson.result))
      return true
    } catch (error) {}
  }
}

export function saveInspiration(inspirationId, type) {
  const appendant = requestHelper({ userInspirationIds: [inspirationId] })

  return async (dispatch, state) => {
    if (state().auth.isAuthenticated === false) {
      dispatch(gotoLogIn())
      return false
    }

    if (state().auth.user) {
      return false
    }

    dispatch(updateQuest(0))

    dispatch(inspirationSavedOptimistic(inspirationId, type))
    try {
      // dispatch(contentIsLiked(inspirationId));
      const response = await fetch(SAVE_USER_INSPIRATION, appendant)
      const responseJson = await response.json()

      if (response.code === 400 || responseJson.code === 141) {
        dispatch(inspirationRemoveOptimistic(inspirationId, type))
        return false
      }
      logEvent('save', {
        content_type: 'inspiration',
        content_id: inspirationId
      })

      dispatch(inspirationSaved(inspirationId, type, responseJson))
      return true
      // console.log(responseJson)
    } catch (error) {
      //dispatch(likeActionHasErrored(true, error.toString()));
    }
  }
}

export function removeInspiration(inspirationId, type) {
  const appendant = requestHelper({ userInsIds: [inspirationId] })

  return async (dispatch, state) => {
    if (state().auth.user) {
      return false
    }

    try {
      dispatch(inspirationRemoveOptimistic(inspirationId, type))
      // dispatch(contentIsLiked(inspirationId));
      const response = await fetch(ARCHIVE_USER_INSPIRATION, appendant)
      const responseJson = await response.json()
      // console.log(responseJson, type)

      logEvent('unsave', {
        content_type: 'inspiration',
        content_id: inspirationId
      })

      dispatch(inspirationRemoved(inspirationId, type, responseJson))
      return true
    } catch (error) {
      //dispatch(likeActionHasErrored(true, error.toString()));
    }
  }
}

export function removeDraft(draftId) {
  const appendant = requestHelper({ objectIds: [draftId] })

  return async (dispatch, state) => {
    if (state().auth.isAuthenticated === false) {
      return false
    }

    try {
      await fetch(DELETE_DRAFT, appendant)

      dispatch(fetchFeed('drafts')({ status: FEED_HARD_RESET }))

      if (
        typeof window !== 'undefined' &&
        window.location.href.includes('/design/')
      ) {
        window.location.href = window.location.host
      }
      return {}
    } catch (error) {}
  }
}

export const getSavedInspiration = data => {
  return {
    data: data.savedUserInspirations.map(item => item.userInspiration),
    productIds: data.productIds,
    type: INSPIRATION_GET
  }
}

export const getSavedInspirationIds = data => {
  return {
    itemIds: Object.values(data.userInspirationIds).reduce((prev, current) => {
      return [...prev, ...current]
    }, []),
    type: INSPIRATION_GET_IDS
  }
}

export const inspirationSaved = (id, feedType, data) => {
  return {
    id: id,
    data: data,
    feedType: feedType,
    type: INSPIRATION_SAVED
  }
}

export const inspirationSavedOptimistic = (id, feedType) => {
  return {
    id: id,
    feedType: feedType,
    type: INSPIRATION_SAVED
  }
}

export const inspirationRemoveOptimistic = (id, feedType) => {
  return {
    id: id,
    feedType: feedType,
    type: INSPIRATION_REMOVED
  }
}

export const inspirationRemoved = (id, feedType, data) => {
  return {
    id: id,
    feedType: feedType,
    data: data,
    type: INSPIRATION_REMOVED
  }
}
