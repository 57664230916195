export const getImageSource = data => {
  const src = {
    supportsSrcSet: false,
    src: '',
    srcSet: null,
    alt: '',
    sizes: ''
  }
  const idea = data.idea ? data.idea : data

  if (idea.collection) {
    const collection = idea.collection
    const urlBase = collection.cdn + collection.key

    const url320w = urlBase + '-320w.jpeg'
    const url480w = urlBase + '-480w.jpeg'
    const url800w = urlBase + '-800w.jpeg'

    src.src = url320w
    src.srcHighRes = url800w
    src.alt = collection.alt
    src.srcSet = url320w + ' 320w, ' + url480w + ' 480w, ' + url800w + ' 800w'
    src.supportsSrcSet = true
    src.sizes = '(max-width: 320px) 320px, (max-width: 480px) 480px, 800px'
  } else {
    if (idea.thumbImageFile) {
      src.src = idea.thumbImageFile.url
    } else if (idea.cfThumbImageUrl) {
      src.src = idea.cfThumbImageUrl
    } else if (data.cfImageUrl) {
      src.src = data.cfImageUrl
    } else if (data.feedImageFile) {
      src.src = data.feedImageFile.url
    } else if (data.userInspiration) {
      src.src = getImageSource(data.userInspiration).src
    } else if (data.cfBgImageUrl) {
      src.src = data.cfBgImageUrl
    }
  }

  return src
}
