import React from 'react'
import { DesignAnimation } from './DesignAnimation'
import { Canvas } from './DesignCanvas.styles'
import { DesignSource } from './DesignSource'

export const DesignCanvas = ({
  scalable,
  data,
  displayRichContent,
  displayAnimation = true,
  borderRadius = 10
}) => {
  return (
    <Canvas
      scalable={scalable}
      displayRichContent={displayRichContent}
      borderRadius={borderRadius}
    >
      <DesignSource data={data} displayRichContent={displayRichContent} />
      {displayAnimation && (
        <DesignAnimation animationInfo={data?.animationInfo} />
      )}
    </Canvas>
  )
}
