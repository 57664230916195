import React from 'react'
import { DesignAnimationContainer } from './DesignCanvas.styles'

export const DesignAnimation = ({ animationInfo }) => {
  if (!animationInfo) return null

  return (
    <DesignAnimationContainer
      loading="lazy"
      decoding="async"
      src={animationInfo.animationUrl}
    />
  )
}
