import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import deleteIcon from '../../assets/modal/delete.svg'
import warningIcon from '../../assets/modal/warning.svg'
import saveIcon from '../../assets/social/save.svg'
import savedIcon from '../../assets/social/saved.svg'
import shareIcon from '../../assets/social/share.svg'
import editIcon from '../../assets/social/edit.svg'

import threeDotImg from '../../assets/threedot.svg'
import {
  removeDraft,
  removeInspiration,
  saveInspiration
} from '../../redux/actions/SaveInspirationAction'
import { OpenShareBox } from '../../redux/actions/ShareAction'
import {
  openReportModal,
  showDeletionModal
} from '../../redux/communityReducers/PostActionsReducer'
import { logEvent } from '../../utils/firebase'
import { getImageSource } from '../../utils/getImageSource'
import DropdownMenu from '../common/DropdownMenu'
import { ButtonWrapper } from './InspirationActionsMenu.styles'

export const InspirationActionsMenu = ({
  location,
  inspirationId,
  imageUrl,
  type,
  dark,
  fromOwner,
  mode,
  isChallengePost,
  sourcePost,
  onDelete,
  showCaptionModal
}) => {
  const dispatch = useDispatch()
  const { savedIds } = useSelector(state => state.saveInspiration)
  let saved = useMemo(() => {
    return typeof savedIds !== 'undefined' && savedIds.includes(inspirationId)
  }, [inspirationId, savedIds])

  const saveClick = useCallback(() => {
    if (!saved) {
      logEvent(location + '_save_clicked')
      dispatch(saveInspiration(inspirationId, type))
    } else {
      logEvent(location + '_unsave_clicked')
      dispatch(removeInspiration(inspirationId, type))
    }
  }, [dispatch, location, saved, type, inspirationId])

  const shareClick = useCallback(() => {
    const tag = location + '_share_clicked'
    logEvent(tag)

    dispatch(
      OpenShareBox({
        id: inspirationId,
        imgUrl:
          imageUrl ||
          getImageSource(sourcePost).srcHighRes ||
          getImageSource(sourcePost).src,
        customUrl: '',
        shareType: type || 'design'
      })
    )
  }, [dispatch, imageUrl, inspirationId, location, type, sourcePost])

  const onDeleteDraftClick = useCallback(() => {
    if (onDelete) onDelete({ objectId: inspirationId })
    else dispatch(removeDraft(inspirationId))
  }, [dispatch, onDelete, inspirationId])

  const actionsList = useMemo(() => {
    const list = []

    if (mode !== 'drafts') {
      list.push({
        icon: saved ? savedIcon : saveIcon,
        label: `${saved ? 'Saved' : 'Save'} Design`,
        highlighted: saved,
        action: saveClick
      })
      list.push({
        icon: shareIcon,
        label: 'Share Design',
        action: shareClick
      })

      if (fromOwner) {
        list.unshift({
          icon: editIcon,
          label: 'Edit',
          action: showCaptionModal
        })
      }

      if (!fromOwner) {
        list.push({
          icon: warningIcon,
          label: 'Report',
          action: () => dispatch(openReportModal(inspirationId))
        })
      } else {
        list.push({
          icon: deleteIcon,
          label: 'Delete',
          action: () =>
            dispatch(
              showDeletionModal({
                postId: inspirationId,
                isChallengePost
              })
            )
        })
      }
    } else {
      list.push({
        icon: deleteIcon,
        label: 'Delete',
        action: () => onDeleteDraftClick()
      })
    }

    return list
  }, [
    mode,
    fromOwner,
    dispatch,
    saveClick,
    shareClick,
    saved,
    inspirationId,
    onDeleteDraftClick,
    isChallengePost
  ])

  return (
    <DropdownMenu
      label={
        <ButtonWrapper dark={dark}>
          <img width={20} height={4} src={threeDotImg} alt="Menu" />
        </ButtonWrapper>
      }
      actions={actionsList}
    />
  )
}
