import styled from 'styled-components'

export const ButtonWrapper = styled.button`
  height: 40px;
  width: 40px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;

  & img {
    width: 20px;
    filter: ${props => (props.dark ? 'invert(0%)' : 'invert(100%)')};
  }

  &:hover {
    background: rgba(255, 255, 255, 0.7);

    & img {
      filter: invert(0%);
    }
  }
`
