import React from 'react'
import { useDispatch } from 'react-redux'
import commentImg from '../../assets/social/comment.svg'
import { openCommentModal } from '../../redux/communityReducers/PostActionsReducer'
import { ActionButton, ActionButtonIcon } from './SocialButtons.styles'

const CommentBtn = ({ data, noButton, postId, commentsCount, ...props }) => {
  const dispatch = useDispatch()

  return (
    <ActionButton
      as={noButton ? 'div' : 'button'}
      onClick={() =>
        dispatch(
          openCommentModal({
            commentNum: commentsCount,
            data: data,
            id: postId
          })
        )
      }
      {...props}
    >
      <ActionButtonIcon src={commentImg} alt="" />
      <span>{commentsCount}</span>
    </ActionButton>
  )
}

export default CommentBtn
