import styled from 'styled-components'

export const Canvas = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: ${props => props.borderRadius}px;
  overflow: hidden;

  & img,
  & iframe {
    width: 100%;
    height: 100%;
    border-radius: ${props => props.borderRadius}px;
    object-fit: cover;
  }

  & iframe {
    min-height: ${props => (props.displayRichContent ? '640px' : '320px')};
  }

  @media (max-width: 800px) {
    border-radius: 0;

    & img,
    & iframe {
      border-radius: 0;
    }
  }

  ${props =>
    !props.scalable &&
    `
    @media (max-width: 800px) {
    min-height: 320px;
    min-width: 320px;
  }
  `}
`

export const DesignAnimationContainer = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
